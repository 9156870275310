body {
  color: #fff;
  background: linear-gradient(to right, #5f5190 0%, #140858 100%);
}

html {
  min-height: 100%;
}

.main {
  height: 100vh;
}

.logodiv {
  height: 20vh;
}

.anidiv {
  height: 50vh;
}

.footerdiv {
  height: 30vh;
}

.logo {
  height: 250px;
}

.mih1 {
  font-weight: bold;
  font-size: 2.7rem;
  letter-spacing: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.subtitle {
  font-size: 1.3rem;
  letter-spacing: 3px;
}

.plus-img {
  height: 60px;
  cursor: pointer;
  border-radius: 1rem;
}

.si {
  height: 50px;
  margin: 0 10px;
}

@media only screen and (max-width: 600px) {
  .rmt {
    margin-top: 1rem;
  }

  .dogimg {
    height: auto !important;
  }

  .main {
    margin: 0 1rem;
  }

  .logodiv {
    height: auto;
    padding: 2rem 0;
  }

  .anidiv {
    height: auto;
  }

  .footerdiv {
    height: auto;
    padding: 2rem 0;
  }
}
